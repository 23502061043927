<div
  ngbDropdown
  placement="bottom"
  autoClose="outside"
  class="kt-header__topbar-item"
>
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
    <span
      class="kt-header__topbar-icon"
      [ngClass]="{
        'kt-pulse kt-pulse--brand': pulse,
        'kt-pulse--light': pulseLight,
        'kt-header__topbar-icon--success': iconType === 'success'
      }"
    >
      <span style="color: brown; size:10px;" *ngIf="notifications">{{notifications.length != 0 ? notifications.length : '' }}</span>

      <i *ngIf="!useSVG" [ngClass]="icon"></i>
      <span
        *ngIf="useSVG"
        class="kt-svg-icon"
        [ngClass]="{ 'kt-svg-icon--success': iconType === 'success' }"
        [inlineSVG]="icon"
      >
    </span>
      <span class="kt-pulse__ring" [hidden]="!pulse"></span>
    </span>
    <span
      class="kt-badge kt-badge--dot kt-badge--notify kt-badge--sm kt-badge--brand"
      [hidden]="!dot"
    > </span>
  </div>

  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg"
  >
    <form>
      <!--begin: Head -->
      <div
        class="kt-head kt-head--skin-{{ skin }} kt-head--fit-x kt-head--fit-b"
        [ngStyle]="{ 'background-image': backGroundStyle() }"
      >
        <h3 class="kt-head__title">
          <span class="btn btn-{{ type }} btn-sm btn-bold btn-font-md" *ngIf="notifications"
            >{{notifications.length}} New</span
          >
        </h3>
        <ul
          ktTabClickEvent
          class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-{{
            type
          }} kt-notification-item-padding-x"
          role="tablist"
        >
          <li class="nav-item">
            <a
              (click)="tab.select('tab-id-1')"
              class="nav-link active show"
              data-toggle="tab"
              href="javascript:;"
              role="tab"
              aria-selected="true"
              >Notifications</a
            >
          </li>
          <!-- <li class="nav-item">
            <a
              (click)="tab.select('tab-id-2')"
              class="nav-link"
              data-toggle="tab"
              href="javascript:;"
              role="tab"
              aria-selected="false"
              >Events</a
            >
          </li>
          <li class="nav-item">
            <a
              (click)="tab.select('tab-id-3')"
              class="nav-link"
              data-toggle="tab"
              href="javascript:;"
              role="tab"
              aria-selected="false"
              >Logs</a
            >
          </li> -->
        </ul>
      </div>
      <!--end: Head -->

      <ngb-tabset #tab="ngbTabset">
        <ngb-tab id="tab-id-1">
          <ng-template ngbTabContent>
            <div
              [perfectScrollbar]="{ wheelPropagation: false }"
              [ngStyle]="{ 'max-height': '40vh', position: 'relative' }"
              class="kt-notification kt-margin-t-10 kt-margin-b-10"
            >
              <a href="javascript:;" class="kt-notification__item" *ngFor="let item of notifications" (click)="notificationSelected(item)">
                <div class="kt-notification__item-icon">
                  <i class="flaticon2-box-1 kt-font-brand"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                     {{item.requestApplication.title}}
                  </div>
                  <div class="kt-notification__item-time">DATE: {{transformDate(item.createDate)}}</div>
                </div>
              </a>

              <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                <div
                  class="ps__thumb-x"
                  tabindex="0"
                  style="left: 0px; width: 0px"
                ></div>
              </div>
              <div class="ps__rail-y" style="top: 0px; right: 0px">
                <div
                  class="ps__thumb-y"
                  tabindex="0"
                  style="top: 0px; height: 0px"
                ></div>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="tab-id-2">
          <ng-template ngbTabContent>
            <div
              [perfectScrollbar]="{ wheelPropagation: false }"
              [ngStyle]="{ 'max-height': '40vh', position: 'relative' }"
              class="kt-notification kt-margin-t-10 kt-margin-b-10"
            >
              <a href="javascript:;" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                  <i class="flaticon2-box-1 kt-font-brand"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    New report has been received
                  </div>
                  <div class="kt-notification__item-time">23 hrs ago</div>
                </div>
              </a>
              <a href="javascript:;" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                  <i class="flaticon-download-1 kt-font-danger"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    Finance report has been generated
                  </div>
                  <div class="kt-notification__item-time">25 hrs ago</div>
                </div>
              </a>
              <a href="javascript:;" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                  <i class="flaticon2-line-chart kt-font-success"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    New order has been received
                  </div>
                  <div class="kt-notification__item-time">2 hrs ago</div>
                </div>
              </a>
              <a href="javascript:;" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                  <i class="flaticon2-box-1 kt-font-brand"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    New customer is registered
                  </div>
                  <div class="kt-notification__item-time">3 hrs ago</div>
                </div>
              </a>
              <a href="javascript:;" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                  <i class="flaticon2-chart2 kt-font-danger"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    Application has been approved
                  </div>
                  <div class="kt-notification__item-time">3 hrs ago</div>
                </div>
              </a>
              <a href="javascript:;" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                  <i class="flaticon2-image-file kt-font-warning"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    New file has been uploaded
                  </div>
                  <div class="kt-notification__item-time">5 hrs ago</div>
                </div>
              </a>
              <a href="javascript:;" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                  <i class="flaticon2-drop kt-font-info"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    New user feedback received
                  </div>
                  <div class="kt-notification__item-time">8 hrs ago</div>
                </div>
              </a>
              <a href="javascript:;" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                  <i class="flaticon2-pie-chart-2 kt-font-success"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    System reboot has been successfully completed
                  </div>
                  <div class="kt-notification__item-time">12 hrs ago</div>
                </div>
              </a>
              <a href="javascript:;" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                  <i class="flaticon2-favourite kt-font-focus"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    New order has been placed
                  </div>
                  <div class="kt-notification__item-time">15 hrs ago</div>
                </div>
              </a>
              <a
                href="javascript:;"
                class="kt-notification__item kt-notification__item--read"
              >
                <div class="kt-notification__item-icon">
                  <i class="flaticon2-safe kt-font-primary"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    Company meeting canceled
                  </div>
                  <div class="kt-notification__item-time">19 hrs ago</div>
                </div>
              </a>
              <a href="javascript:;" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                  <i class="flaticon2-psd kt-font-success"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    New report has been received
                  </div>
                  <div class="kt-notification__item-time">23 hrs ago</div>
                </div>
              </a>
              <a href="javascript:;" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                  <i class="flaticon-download-1 kt-font-danger"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    Finance report has been generated
                  </div>
                  <div class="kt-notification__item-time">25 hrs ago</div>
                </div>
              </a>
              <a href="javascript:;" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                  <i class="flaticon-security kt-font-warning"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    New customer comment recieved
                  </div>
                  <div class="kt-notification__item-time">2 days ago</div>
                </div>
              </a>
              <a href="javascript:;" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                  <i class="flaticon2-pie-chart kt-font-focus"></i>
                </div>
                <div class="kt-notification__item-details">
                  <div class="kt-notification__item-title">
                    New customer is registered
                  </div>
                  <div class="kt-notification__item-time">3 days ago</div>
                </div>
              </a>
              <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                <div
                  class="ps__thumb-x"
                  tabindex="0"
                  style="left: 0px; width: 0px"
                ></div>
              </div>
              <div class="ps__rail-y" style="top: 0px; right: 0px">
                <div
                  class="ps__thumb-y"
                  tabindex="0"
                  style="top: 0px; height: 0px"
                ></div>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="tab-id-3">
          <ng-template ngbTabContent>
            <div class="kt-grid kt-grid--ver" style="min-height: 200px">
              <div
                class="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle"
              >
                <div
                  class="kt-grid__item kt-grid__item--middle kt-align-center"
                >
                  All caught up!
                  <br />No new notifications.
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </form>
  </div>
</div>
