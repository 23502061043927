export const locale = {
  lang: "fa",
  data: {
    TRANSLATOR: {
      SELECT: "انتخاب زبان",
    },
    MENU: {
      NEW: "جدید",
      ACTIONS: "فعالیت ها",
      CREATE_POST: "پوست جدید",
      PAGES: "صحفات",
      FEATURES: "خصوصیات",
      COMPONENTS: "بخش ها",
      APPS: "اپلیکشن ها",
      DASHBOARD: "دشبورد",
      CUSTOMS: "کستومز",
      HOME: "خانه",
      ADMINISTRATION: "تنظیمات",
      PROVINCE: "ولایت", 
      ORGANIZATION: "سازمان", 
      CENTER: "مرکز", 
      AGENT: "نماینده", 
      AGENT_MANAGEMENT: "مدیریت نماینده ها",
      CREDIT_AGENT: "کریدت حساب نماینده",
      DEBIT_AGENT: "دبت حساب نماینده",
      BILLTYPE: "نوعیت بل ", 
      FEE_MODEL: "فیس",
      AGENT_BILL_PAYMENT: "پرداخت بل  ", 
      AGENT_SLIP_PRINT: "چاپ آویز", 
      BALANCE_SHEET: "بیلانس شیت",
      MONITORING: "ارزیابی و نظارت", 
      RECONCILIATION: "سردهی معلومات", 
      STATEMENT: "استتمنت", 
      CENTER_USER_RELATION: "مرکز و یوزر", 
      CONFIRM_BILL_PAYMENT: "تاییدی پرداخت بل", 
      ISSUE_BILL: "بل جدید",
      ORGANIZATION_USER: "کارمند", 
      CENTER_MANAGER: "مدیر مرکز",
      ORGANIZATION_ADMIN: "مدیرکل اداره", 
      CREDIT_ORGANIZATION: "کریدت حساب سازمان",
      DEBIT_ORGANIZATION: "دبت حساب سازمان",
      ORGANIZATION_BALANCE_SHEET: "بیلانس شیت سازمان",
      ORGANIZATION_REVENUE_REPORT: "راپور عواید سازمان",
      AGENT_REVENUE_REPORT: "راپور عواید نماینده",
      AGENT_BALANCE_SHEET: "بیلانس شیت نماینده",
      AGENT_MAKE_PAYMENT: "پرداخت برای نماینده",
      OUR_REVENUE_REPORT: "عواید کمپنی",
      FINANCE: "مالی",
      PAY_ONLINE_BILL: "پرداخت بل آنلاین",
      THIRD_PARTY_INTEGRATION: "وصل با سیستم بیرونی",
      AGENT_REVENUE: "عواید نماینده",
      REPORTING: "گزارش گیری",
    },
    CAREFULL_MESSAGE: "با وجود داشتن صلاحیت دسترسی به این بخش،‌لطفا بسیار محتاط باشید. ",
    ISSUE_BILL: "بل جدید",
    BILL_TYPE: "نوعیت بل",
    CENTER: "مرکز",
    NUMBER_OF_ITEMS: "تعداد موارد",
    IDENTIFIER: "شناس نمبر",
    BACK: "عقب",
    SUBMIT: "ثبت",
    BILL_PAYMENT_CONFIRMATION: "تنظیمات پرداخت بل",
    RECEIPT_NUMBER: "نمبر رسید",
    DISPLAY: "نمایش",
    BILL_DETAILS: "جزییات بل", 
    BILL_NO: "نمبر بل",
    BILL_AMOUNT: "مقدار بل",
    BILL_DATE: "تاریخ بل",
    AGENT_NAME: "نام نماینده",
    AGENT_ACCOUNT_NO: "نمبر حساب نماینده",
    AGENT_PHONE_NO: "شماره تماس نماینده",
    PAYMENT_AMOUNT: "مقدار پرداخت",
    PAYMENT_DATE: "تاریخ پرداخت",
    CONFIRM_PAYMENT: "تایید پرداختی",
    CONFIRM_USER: "یوزر تایید کننده",
    CONFIRM_DATE: "تاریخ تایید",
    CONFIRMED: "تایید",
    VIEW: "نمایش",
    PRINT: "پرنت",
    CLOSE: "بسته", 
    AUTH_ACTIONS: "فعالیت های هویتی",
    LOGOUT: "خروج",
    CHANGE_PASSWORD: "تغییر پاسورد",
    GENEATE_REPORT:"تولید راپور ها",
    FROM_DATE: "از تاریخ",
    TO_DATE: "تا تاریخ",
    STATUS: "وضیعت",
    EXPORT: "اکسپورت",
    GENERATE_STATEMENT: "تولید استتمنت",
    REVENUE_REPORT: "راپور عواید",
    ORGANIZATION: "سازمان",
    ORGANIZATION_BALANCE_SHEET: "بیلانس شیت سازمان",
    ACCOUNT_NUMBER: "نمبر حساب",
    PLEASE_ENTER_THE_ACCOUNT_NUMBER: "نمبر حساب را وارد کنید",
    SEARCH: "جستجو",
    BALANCE: "بیلانس",
    CREDIT: "کریدت", 
    DEBIT: "دبت",
    BALANCE_DATE: "تاریخ بیلانس",
    PREVIOUS: "قبلی",
    RJ_REASON: "دلیل تغییرات ژورنان",
    JOURNAL_REFERENCE_NUMBER: "نمبر ژورنال",
    AMOUNT: "مقدار",
    PROVINCE: "ولایت",
    RJ_TYPE: "نوعیت تغییرات ژورنال",
    COLLECTION_DATE: "تاریخ جمع آوری",
    RJ_DATE: "تاریخ تغییرات ژورنال",
    CODE: "کد",
    NAME: "نام",
    JOURNAL_ENTRY: "ریکورد ژورنال",
    CREDIT_JOURNAL_ENTRY: "کریدت ژورنال",
    ORGANIZATION_ACCOUNT: "حساب سازمان",
    ACCOUNT_NO: "نمبر حساب",
    DEBIT_JOURNAL_ENTRY: "دبت ژورنال",
    AGENT_ACCOUNT: "حساب نماینده",
    FIRSTNAME: "اسم",
    LASTNAME: "تخلص",
    AGENT_FEE_APPROVAL: "تاییدی فیس و عواید نماینده",
    ALL: "همه",
    AGENT_FEE: "فیس نماینده",
    FEE_DATE: "تاریخ فیس",
    IS_CLEARED: "تصفیه شده؟",
    APPROVE: "تایید",
    REJECT: "رد",
    BALANCE_SHEET: "بیلانس شیت",
    AGENT_PAYMENT: "پرادخت به نماینده",
    PAYMENT_JOURNAL_ENTRY: "ژورنال پرداخت",
    OFFLINE_BILL_PAYMENT: "پرداخت بل",
    BILL_NUMBER: "نمبر بل",
    BILL_INFORMATION: "معلومات بل",
    BILL_DUE_DATE: "تاریخ پرداخت بل",
    CYCLE: "دوره",
    YEAR: "سال",
    PAYMENT_DETAILS: "جزییات پرداخت",
    MODE_OF_PAYMENT: "مد پرداخت",
    CASH: "نقد",
    ELECTRONIC: "پول الکترونیکی",
    TENDERED_AMOUNT: "مقدار مناقصه",
    AMOUNT_PAID: "مقدار پرداخت",
    RETURN_AMOUNT: "مقدار برگشت",
    ONLINE_BILL_PAYMENT: "پرداخت بل آنلاین",  
    ARE_YOU_SURE: "آیا مطمٔن هستید؟",
    PROCESSING:  "در حال پراسس .....",
    THE_OPERATION_SUCCESSFULLY_DONE: "درخواست موفقانه پراسس شد. تشکر از شما",
    CONFIRMATION: "نیاز ه تاییدی",
    DUPLICATE_SLIP_PRINT: "پرنت تکراری آویز",
    PROVINCE_CODE: "کد ولایت",
    ACTIONS: "فعالیت ها",
    ADD_PROVINCE:"ایجاد ولایت",
    PROVINCE_USER_RELATION: "ولایت و یوزر",
    USERNAME: "نام حساب کاربری",
    DELETED: "جذف شده؟",
    ADD_ORGANIZATION: "ایجاد سازمان",
    PHONE: "شماره تلیفون",
    EMAIL: "آدرس الکترونیکی",
    ADDRESS: "آدرس",
    BANK_NAME: "نام بانک",
    BANK_ACCOUNT_NO: "نمبر حساب بانکی",
    BANK_CARD_HOLDER_NAME: "نام دارنده کارت",
    BANK_CARD_NO: "نمبر کارت اف پی",
    ORGANIZATION_USER_RELATION: "سازمان و یوزر",
    CENTER_USER_RELATION: "مرکز و یوزر",
    ADD_CENTER: "ایجاد مرکز",
    PARENT_CENTER: "مرکز پدر",
    AGENT_USER_RELATION: "نماینده و یوزر",
    ADD_AGENT: "ایجاد نماینده",
    FATHERNAME: "نام پدر",
    GRANT_FATHERNAME: "نام پدر کلان",
    TAZKIRA_NO: "نمبر تذکره",
    FRIEND_ACCOUNT_NO1: "حساب نمبر دوست ۱",
    FRIEND_ACCOUNT_NO2: "حساب نمبر دوست ۲",
    PRICE_PER_ITEM: "قیمت هر مورد",
    ADD_BILL_TYPE: "اضافه نوعیت بل",
    FEE_MODEL: "مدل فیس",
    BILLING_CHANNEL: "چنل بلنگ",
    THIRD_PARTY_INTEGRATION: "وصل با سیستم بیرونی",
    PERCENTAGE: "فیصدی",
    ADD_FEE_MODEL: "اضافه مدل فیس",
    TYPE: "نوعیت",
    IS_ITEM_BASED: "به اساس مورد؟",
    AGENT_FEE_AMOUNT: "مقدار فیس نماینده",
    AGENT_FEE_PERCENTAGE: "فیصدی فیس نماینده",
    FEE_INCLUSION: "شمولیت فیس",
    HOST: "هاست",
    PORT: "پورت",
    AUTH_URI: "URL تثبیت هویت",
    BILL_INQUIRY_URI: "URL  درخواست معلومات بل",
    POST_BILL_URI: "URL ارسال معلومات بل",
    ADD_THIRD_PARTY_INTEGRATION: "ایجاد وصل با سیستم بیرونی",
    AUTH_URI_METHOD: "URI Method تثبیت هویت",
    BILL_INFO_INQUIRY_URI: "URL  درخواست معلومات بل",
    BILL_INFO_INQUIRY_URI_METHOD: "URL Method درخواست معلومات بل",
    BILL_ADVICE_MESSAGE_URI: "URL ارسال معلومات بل",
    BILL_ADVICE_MESSAGE_URI_METHOD: "URL Method ارسال معلومات بل",
    AUTHORIZATION_TYPE: "نوعیت شناسای و تثبیت هویت",
    CREDENTIAL_POSITION: "موقیعت معلومات هویت",
    DATA_EXCHANGE_PROTOCOL: "پروتوکل تبادله معلومات",
    API_KEY: "API Key",
    API_VALUE: "API Value",
    CLIENT_ID: "Client ID",
    CLIENT_SECRET: "Client Secret",    
    NEW: "جدید",
    UN_AUTHORIZED_ACCESS: "شما صلاحیت دسترسی به این بخش سیستم را ندارید",

    // This is the dynamic module translation which should be loaded on each module loaded
    M: {},
  },
};
