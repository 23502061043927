<!-- begin:: Brand -->
<div class="kt-header__brand kt-grid__item" [ngClass]="htmlClassService.getClasses('brand', true)" id="kt_header_brand">
	<div class="kt-header__brand-logo">
		<a href="javascript:;" routerLink="/">
			<img alt="logo" [attr.src]="headerWHOLogo" style="width: 120px;"/> <!--Default width: 70px-->
			<img alt="logo" [attr.src]="headerHCLogo" style="width: 130px;"/> 

		</a>
	</div>
</div>
<!-- end:: Brand -->
