export const ngoOfficerMenuModule = {
    title: 'ORGANIZATION_ADMIN',
    root: true,
    alignment: 'left',
    toggle: 'click',
    translate: 'MENU.NGO',
    page: '',
    submenu: [
          {
                title: 'MENU.REQUEST_APPLICATION_FORM',
                page: '/request-application/add',
                translate: "MENU.REQUEST_APPLICATION_FORM"
            },

          {
              title: 'MENU.TRACK_APPLICATIONS',
              page: '/request-application',
              translate: "MENU.TRACK_APPLICATIONS"
          },       

          {
            title: 'MENU.BENEFICIARY_REPORT',
            page: '/medical-supply-consumption',
            translate: "MENU.BENEFICIARY_REPORT"
           }, 
    ]
}