<kt-portlet>
    <kt-portlet-body>
        <div class="kt-heading kt-heading--md">
            CHANGE_PASSWORD
        </div>
        <hr />
        <ng-container>
            <!-- <div class="row"> -->
            <!-- <div class="col-lg-2"></div> -->
            <div>
                <!--begin::Form-->
                <form [formGroup]="eBreshnaForm" (ngSubmit)="submit()">
                    <div class="row">
                        <div class="form-group col-lg-6 required">
                            <label class="col-form-label">USER_NAME</label>
                            <input type="text" class="form-control" formControlName="username" nprintTypeame="name"
                                placeholder="USER_NAME" readonly>
                            <print-error [control]="eBreshnaForm.get('username')"></print-error>
                        </div>
                        <div class="form-group col-lg-6 required">
                            <label class="col-form-label">NEW_PASSWORD</label>
                            <input type="password" class="form-control" formControlName="password"
                                placeholder="NEW_PASSWORD" />
                            <print-error [control]="eBreshnaForm.get('password')"></print-error>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="form-group col-lg-6 required">
                            <label class="col-form-label">PASSWORD</label>
                            <input type="password" class="form-control" formControlName="currentPassword"
                                placeholder="CURRENT_PASSWORD" />
                            <print-error [control]="eBreshnaForm.get('currentPassword')"></print-error>
                        </div> -->
                        <div class="form-group col-lg-6 required">
                            <label class="col-form-label">CONFIRM_NEW_PASSWORD</label>
                            <input type="password" class="form-control" formControlName="confirmPassword"
                                placeholder="CONFORM_NEW_PASSWORD" />
                            <print-error [control]="eBreshnaForm.get('confirmPassword')"></print-error>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <button matTooltip="Save changes" [disabled]="eBreshnaForm.invalid" type="submit"
                            class="btn btn-primary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u">
                            SAVE</button>&nbsp;
                        <button type="button" mat-raised-button [mat-dialog-close]="" cdkFocusInitial
                            matTooltip="Cancel changes">
                            CLOSE
                        </button>&nbsp;
                    </div>
                </form>
                <!--end::Form-->
            </div>
            <!-- </div> -->
        </ng-container>
    </kt-portlet-body>
</kt-portlet>
<ngx-spinner></ngx-spinner>