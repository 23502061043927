export const administrationMenuModule = {
    title: 'Administration',
    root: true,
    alignment: 'left',
    toggle: 'click',
    translate: 'MENU.ADMINISTRATION',
    page: '',
    submenu: [
           {
                title: 'ORGANIZATION_UNIT',
                page: '/province',
                translate: "MENU.ORGANIZATION_UNIT"
            },
            {
              title: 'NGO_ORGANIZATION',
              page: '/organization',
              translate: "MENU.NGO_ORGANIZATION"
            },
            {
              title: 'STOCK',
              page: '/stock',
              translate: "MENU.STOCK"
            },

            {
              title: 'ITEM_CATEGORY',
              page: '/item-category',
              translate: "MENU.ITEM_CATEGORY"
            },

            // {
            //   title: 'ITEM_TYPE',
            //   page: '/item-category/item-type',
            //   translate: "MENU.ITEM_TYPE"
            // },

            {
              title: 'SEARCH_APPLICATION',
              page: '/request-application',
              translate: "MENU.SEARCH_APPLICATION"
            },

            
            {
              title: 'UPDATE_WITH_HeRAMS',
              page: '/province/update-with-herams',
              translate: "MENU.UPDATE_WITH_HeRAMS"
            },
            
    ]
}