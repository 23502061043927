export const procurementOfficerMenuModule = {
    title: 'PROCUREMENT_OFFICER',
    root: true,
    alignment: 'left',
    toggle: 'click',
    translate: 'MENU.LOGISTIC',
    page: '',
    submenu: [
        {
            title: 'MENU.TRACK_APPLICATIONS',
            page: '/request-application',
            translate: "MENU.TRACK_APPLICATIONS"
        },     

        {
            title: 'MENU.STOCK_MANAGEMENT',
            page: '/stock-management',
            translate: "MENU.STOCK_MANAGEMENT"
        }, 
        
        {
            title: 'MENU.DISTRIBUTION_APPLICATIONS',
            page: '/distribution-application',
            translate: "MENU.DISTRIBUTION_APPLICATIONS",
            // toggle: "click",
            // submenu: [
            //         {
            //             title: "MENU.LIST_DISTRIBUTION_APPLICATIONS",
            //             page: "",
            //             translate: "MENU.LIST_DISTRIBUTION_APPLICATIONS",
            //         },
            //         {
            //             title: "MENU.ADD_DISTRIBUTION_APPLICATIONS",
            //             page: "/add",
            //             translate: "MENU.ADD_DISTRIBUTION_APPLICATIONS",
            //         },
            // ]
        }, 

           {
                        title: "MENU.UPLOAD_DNS",
                        page: "/distribution-application/dns",
                        translate: "MENU.UPLOAD_DNS",
            },
        
        {
            title: 'MENU.UPLOAD_STOCK_ITEMS',
            page: '/stock-management/upload-stock-items',
            translate: "MENU.UPLOAD_STOCK_ITEMS"
        }, 
        {
            title: 'MENU.EXPIRATION_DATE_VIEW',
            page: '/stock-management/kits-expiration-date',
            translate: "MENU.EXPIRATION_DATE_VIEW"
        },  
        
        // {
            //   title: 'Organization',
            //   page: '',
            //   translate: "MENU.ORGANIZATION",
            //   alignment: "center",
            //   toggle: "click",
            //   submenu: [
            //     {
            //         title: "CREDIT_ORGANIZATION",
            //         page: "/organization/credit",
            //         translate: "MENU.CREDIT_ORGANIZATION",
            //     },
            //     {
            //         title: "DEBIT_ORGANIZATION",
            //         page: "/organization/debit",
            //         translate: "MENU.DEBIT_ORGANIZATION",
            //     },
            //     {
            //       title: "ORGANIZATION_BALANCE_SHEET",
            //       page: "/organization/balance-sheet",
            //       translate: "MENU.ORGANIZATION_BALANCE_SHEET",
            //     },
            //     {
            //         title: 'ORGANIZATION_REVENUE_REPORT',
            //         page: '/organization-admin/generate-date-based-statement',
            //         translate: "MENU.ORGANIZATION_REVENUE_REPORT"
            //     }
            //   ]
            // },
    ]
}