import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const _BASE_URL = "/api/request-application";
@Injectable({
  providedIn: 'root'
})
export class RequestApplicationService {

  constructor(private httpClient: HttpClient) { }


  public saveApplication(data): Observable<any> {
    return this.httpClient.post(`${_BASE_URL}/save-application`,data);
  }


  public updateNotficationHistory(id): Observable<any> {
    return this.httpClient.get(`${_BASE_URL}/update-notification-history/${id}`);
  }


  public findByCreatedBy(username): Observable<any> {
    return this.httpClient.post(`${_BASE_URL}/find-by-createdby`,username);
  }

  public findDistriutedApplications(): Observable<any> {
    return this.httpClient.get(`${_BASE_URL}/find-distributed-applications`);
  }

  public getRequestApplicationHistories(): Observable<any> {
    return this.httpClient.get(`${_BASE_URL}/notifications`);
  }

  public deleteApplication(id): Observable<any>{
    return this.httpClient.delete(`${_BASE_URL}/delete-request-application/${id}`);
  }

  public changeDraftStatus(dto): Observable<any> {
    return this.httpClient.post(`${_BASE_URL}/change-draft-status`,dto);
  }


  public updateRequestedItem(dto): Observable<any> {
    return this.httpClient.post(`${_BASE_URL}/update-requested-item`,dto);
  }


  public storeApplicationProcessChange(dto): Observable<any> {
    return this.httpClient.post(`${_BASE_URL}/application-process-change`,dto);
  }

  public getRequestApplicationItems(requestId): Observable<any> {
    return this.httpClient.post(`${_BASE_URL}/get-request-application-items`,requestId);
  }

  public getHistoryOfRequestApplication(id): Observable<any>{
    return this.httpClient.get(`${_BASE_URL}/get-history-of-request-application/${id}`);
  }


  public findJustificationsByRqAppId(id): Observable<any>{
    return this.httpClient.get(`${_BASE_URL}/request-hf-justifications/${id}`);
  }

  public findByProcessPhaseAndProvinceAndAndDraft(id): Observable<any>{
    return this.httpClient.get(`${_BASE_URL}/get-applications-by-province/${id}`);
  }


  public findApplicationsByProcessPhase(processPhase): Observable<any>{
    return this.httpClient.get(`${_BASE_URL}/get-applications-by-process-phase/${processPhase}`);
  }

  public getAnalysisOfRequestApplication(id): Observable<any>{
    return this.httpClient.get(`${_BASE_URL}/get-analysis-of-request-application/${id}`);
  }

  public getRequestedItemModificationHistory(id): Observable<any>{
    return this.httpClient.get(`${_BASE_URL}/requested-item-modification-history/${id}`);
  }

  public getAnalysisOfRequestApplicationForAStock(requestId, stockId): Observable<any>{
    return this.httpClient.get(`${_BASE_URL}/get-analysis-of-request-application-for-a-stock/${requestId}/${stockId}`);
  }

  
  public getRequestedItemUtilizationAnalysis(id): Observable<any>{
    return this.httpClient.get(`${_BASE_URL}/requested-item-utilization-analysis/${id}`);
  } 
  public getItemAvailableInStock(itemId): Observable<any>{
    return this.httpClient.get(`${_BASE_URL}/get-items-available-in-stock/${itemId}`);
  }

  public getApplicationById(applicationId): Observable<any>{
    return this.httpClient.get(`${_BASE_URL}/application-by-id/${applicationId}`);
  }


  public getRequestApplicationsByApprovalStatus(status): Observable<any>{
    return this.httpClient.post(`${_BASE_URL}/get-request-application-by-approval-status`, status);
  }

  
}
