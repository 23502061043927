export const locale = {
  lang: "en",
  data: {
    TRANSLATOR: {
      SELECT: "Select your language",
    },
    MENU: {
      BENEFICIARY_REPORT: "Beneficiary Report",
      EXPIRATION_DATE_VIEW: "Kits Expiration Date Analysis",
      UPDATE_WITH_HeRAMS: "Update With HeRAMS Data",
      UPLOAD_STOCK_ITEMS: "Upload Items",
      NGO_REGISTRATION: "NGO Registration",
      MEDICAL_SUPPLY_CONSUMPTION: "Medical Supply Consumption",
      ITEM_CATEGORY: "Item Category",
      ITEM_TYPE: "Item Type",
      NGO : "NGO",
      SUB_OFFICE: "Sub Office",
      APPROVER: "APPROVER",
      LOGISTIC: "LOGISTIC",
      NEW: "new",
      ACTIONS: "Actions",
      CREATE_POST: "Create New Post",
      PAGES: "Pages",
      FEATURES: "Features",
      COMPONENTS: "Components",
      APPS: "Applications",
      DASHBOARD: "Dashboard",
      CUSTOMS: "Customs",
      HOME: "Home",
      PDA: "PDA",
      STORE_PROCESSED_DOCS: "Register Document",
      SEARCH_PROCESSED_DOCS: "Search Document",
      VERIFY_PROCESSED_DOCS: "Verify Document",
      ADMINISTRATION: "Settings",
      PROVINCE: "Province", 
      ORGANIZATION: "Organization", 
      CENTER: "Center", 
      AGENT: "Agent", 
      AGENT_MANAGEMENT: "Agent Management",
      CREDIT_AGENT: "Credit Agent Account",
      DEBIT_AGENT: "Debit Agent Account",
      BILLTYPE: "Bill Type", 
      FEE_MODEL: "Fee Model",
      AGENT_BILL_PAYMENT: "Agent Bill Payment", 
      AGENT_SLIP_PRINT: "Agent Slip Print", 
      BALANCE_SHEET: "Balance Sheet",
      MONITORING: "Monitoring", 
      RECONCILIATION: "Reconciliation", 
      STATEMENT: "Statement", 
      CENTER_USER_RELATION: "Center User Relation", 
      CONFIRM_BILL_PAYMENT: "Confirm Bill Payment", 
      ISSUE_BILL: "Issue New Bill",
      ORGANIZATION_USER: "Officer", 
      CENTER_MANAGER: "CManager",
      ORGANIZATION_ADMIN: "OManager", 
      CREDIT_ORGANIZATION: "Credit Organization",
      DEBIT_ORGANIZATION: "Debit Organization",
      ORGANIZATION_BALANCE_SHEET: "Organization Balance Sheet",
      ORGANIZATION_REVENUE_REPORT: "Organization Revenue Report",
      AGENT_REVENUE_REPORT: "Agent Revenue Report",
      AGENT_BALANCE_SHEET: "Agent Balance Sheet",
      AGENT_MAKE_PAYMENT: "Agent Make Payment",
      OUR_REVENUE_REPORT: "Company Revenue",
      FINANCE: "Finance",
      PAY_ONLINE_BILL: "Pay Online Bill",
      THIRD_PARTY_INTEGRATION: "Third Party Integration",
      AGENT_REVENUE: "Agent RevNGO_ORGANIZATIONenue",
      REPORTING: "Reporting",

      ORGANIZATION_UNIT: "Organization Unit",
      NGO_ORGANIZATION: "NGO Organization",
      STOCK: "Stock",
      SEARCH_APPLICATION: "Search Request Application",
      NGO_OFFICER: "NGO Officer",
      REQUEST_APPLICATION_FORM: "Request Application Form",
      TRACK_APPLICATIONS: "Applications",
      REGIONAL_OFFICER: "Regional Officer",
      EMERGENCY_OFFICER: "Emergency Officer",
      TEAM_LEAD: "Team Lead",
      PROCUREMENT_OFFICER: "Procurement Officer",
      STOCK_MANAGEMENT: "Stock Management",
      DISTRIBUTION_APPLICATIONS: "Distribution Applications",
      LIST_DISTRIBUTION_APPLICATIONS: "List Distribution Applications",
      ADD_DISTRIBUTION_APPLICATIONS: "Add Distribution Application"
    },

    JUSTIFICATIONS : "Justifications",
    REQUEST_INFORMATION_SECTION: "Request Information Section",
    KIT_SELECTION_SECTION : "Request Kits Section",
    ENTER_REQUEST_INFORMATION: "Enter Request Information",
    SELECT_KITS_FOR_HF: "Select Kits for Health Facilities",
    REQUEST_FOR_ANOTHER_HF: "Request for Next Health Facility",
    REQUEST_TITLE: "Request Title",
    REQUEST_DESCRIPTION: 'Description About Request',
    REQUEST_TYPE: "Type of Request",
    NEXT_STEP : "NEXT",
    DISTRICT : "District",
    HEALTH_FACILITY: "Health Facility",
    IS_SAHAMADI_HF: "Is Sehatmandi Health Facility",
    ITEM_CATEGORY: "Item Category",
    ITEM : "Item",
    FIRST_TIME_REQUEST_OF_ITEM: "First Time Request for this Item?",
    REQUEST_QUANTITY : "Request Quantity",
    CURRENT_BALANCE_FROM_LAST_RELEASE : "Current Balance from Last Release",
    ADD : "Add",
    ITEM_UNIT : "Unit of Measurement",
    JUSTIFICATION : "Justification",
    COMMENTS: "Comments",
    PLANNED_YEAR: "Planned Year",
    QUARTER: "Quarter",
    TITLE: "Title",
    PHASE : "Phase",
    APP_NO : "App No",
    APPROVAL_STATUS: "Approval Status",
    REQUEST_APPLICATION : "Request Application",
    NGO_NAME : "NGO Name",
    NGO_ADDRESS: "NGO Address",
    NGO_SUPPORTING_HEALTH_FACILITIES: "NGO Supporting Health Facilities",
    DESCRIPTION : "Description",
    REQUEST_APPLICATION_NO : "Request Application No",
    REQUESTED_ITEMS : "Requested Items",
    REQUESTED_QUANTITY : "Requested Quantity",
    FIRST_TIME_REQUEST : "First Time Request",
    CURRENT_BALANCE : "Current Balance",
    DISTRIBUTED_ITEMS : "Distributed Items",
    DISTRIBUTED_QUANTITY: "Distributed Quantity",
    DISTRIBUTED_ITEM_CONSUMPTION_REPORT : "Consumption Report for Distributed Items",
    DISTRIBUTED_ITEM_NAME: "Distributed Item Name",
    DISTRIBUTED_ITEM_CODE: "Distributed Item Code",
    CONSUMPTION_PERCENTAGE: "Consumption Percentage",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    CONUMPTION_PERCENTAGE: "Consumption Percentage", 
    // PREVIOUS: "Previous",
    // SUBMIT : "Submit",

    CAREFULL_MESSAGE: "	We know that you are authorized to access this part of the system. Considering that, this is a critical module. Please be careful!",
    ISSUE_BILL: "Issue Bill",
    BILL_TYPE: "Bill Type",
    CENTER: "Center",
    NUMBER_OF_ITEMS: "Number Of Items",
    IDENTIFIER: "Identifier",
    BACK: "Back",
    SUBMIT: "Submit",
    BILL_PAYMENT_CONFIRMATION: "Bill Payment Configuration",
    RECEIPT_NUMBER: "Receipt Number",
    DISPLAY: "Display",
    BILL_DETAILS: "Bill Details", 
    BILL_NO: "Bill Number",
    BILL_AMOUNT: "Bill Amount",
    BILL_DATE: "Bill Date",
    AGENT_NAME: "Agent Name",
    AGENT_ACCOUNT_NO: "Agent Account No",
    AGENT_PHONE_NO: "Agent Phone No",
    PAYMENT_AMOUNT: "Payment Amount",
    PAYMENT_DATE: "Payment Date",
    CONFIRM_PAYMENT: "Confirm Payment",
    CONFIRM_USER: "Confirmation User",
    CONFIRM_DATE: "Confirmation Date",
    CONFIRMED: "Confirmed",
    VIEW: "View",
    PRINT: "Print",
    CLOSE: "Close", 
    AUTH_ACTIONS: "Auth Actions",
    LOGOUT: "Logout",
    CHANGE_PASSWORD: "Change Password",
    GENEATE_REPORT:"Generate Reports",
    FROM_DATE: "From Date",
    TO_DATE: "To Date",
    STATUS: "Status",
    EXPORT: "Export",
    GENERATE_STATEMENT: "Generate Statement",
    REVENUE_REPORT: "Revenue Report",
    ORGANIZATION: "Organization",
    ORGANIZATION_BALANCE_SHEET: "Organization Balance Sheet",
    ACCOUNT_NUMBER: "Account Number",
    PLEASE_ENTER_THE_ACCOUNT_NUMBER: "Please Enter Account Number",
    SEARCH: "Search",
    BALANCE: "Balance",
    CREDIT: "Credit", 
    DEBIT: "DEBIT",
    BALANCE_DATE: "Balance Date",
    PREVIOUS: "Previous",
    RJ_REASON: "RJ Reason",
    JOURNAL_REFERENCE_NUMBER: "Journal Reference Number",
    AMOUNT: "Amount",
    PROVINCE: "Province",
    RJ_TYPE: "RJ Type",
    COLLECTION_DATE: "Collection Date",
    RJ_DATE: "RJ Date",
    CODE: "Code",
    NAME: "Name",
    JOURNAL_ENTRY: "Journal Entry",
    CREDIT_JOURNAL_ENTRY: "Credit Journal Entry",
    ORGANIZATION_ACCOUNT: "Organization Account",
    ACCOUNT_NO: "Account Number",
    DEBIT_JOURNAL_ENTRY: "Debit Journal Entry",
    AGENT_ACCOUNT: "Agent Account",
    FIRSTNAME: "First Name",
    LASTNAME: "Last Name",
    AGENT_FEE_APPROVAL: "Agent Fee and Revenue Approval",
    ALL: "All",
    AGENT_FEE: "Agent Fee",
    FEE_DATE: "Fee Date",
    IS_CLEARED: "Is Cleared",
    APPROVE: "Approve",
    REJECT: "Reject",
    BALANCE_SHEET: "Balance Sheet",
    AGENT_PAYMENT: "Agent Payment",
    PAYMENT_JOURNAL_ENTRY: "Payment Journal Entry",
    OFFLINE_BILL_PAYMENT: "Bill Payment",
    BILL_NUMBER: "Bill Number",
    BILL_INFORMATION: "Bill Information",
    BILL_DUE_DATE: "Bill Due Date",
    CYCLE: "Cycle",
    YEAR: "Year",
    PAYMENT_DETAILS: "Payment Details",
    MODE_OF_PAYMENT: "Mode Of Payment",
    CASH: "Cash",
    ELECTRONIC: "Electronic",
    TENDERED_AMOUNT: "Tendered Amount",
    AMOUNT_PAID: "Amount Paid",
    RETURN_AMOUNT: "Return Amount",
    ONLINE_BILL_PAYMENT: "Online Bill Payment",  
    ARE_YOU_SURE: "Are You Sure?",
    PROCESSING:  "Processing Request ....",
    THE_OPERATION_SUCCESSFULLY_DONE: "The Request Has Successfull Processed. Thank you",
    CONFIRMATION: "Need Your Confirmation",
    DUPLICATE_SLIP_PRINT: "Duplicate Slip Print",
    PROVINCE_CODE: "Province Code",
    ACTIONS: "Actions",
    ADD_PROVINCE:"Add Province",
    PROVINCE_USER_RELATION: "Province User Relation",
    USERNAME: "UserName",
    DELETED: "Deleted",
    ADD_ORGANIZATION: "Add Organization",
    PHONE: "Phone",
    EMAIL: "Email",
    ADDRESS: "Address",
    BANK_NAME: "Bank Name",
    BANK_ACCOUNT_NO: "Bank Account Number",
    BANK_CARD_HOLDER_NAME: "Bank Card Holder Name",
    BANK_CARD_NO: "Bank Card Number",
    ORGANIZATION_USER_RELATION: "Organization User Relation",
    CENTER_USER_RELATION: "Organization User Relation",
    ADD_CENTER: "Add Center",
    PARENT_CENTER: "Parent Center",
    AGENT_USER_RELATION: "Agent User Relation",
    ADD_AGENT: "Add Agent",
    FATHERNAME: "Father Name",
    GRANT_FATHERNAME: "Gant Father Name",
    TAZKIRA_NO: "Tazkira Number",
    FRIEND_ACCOUNT_NO1: "Friend Account No1",
    FRIEND_ACCOUNT_NO2: "Friend Account No2",
    PRICE_PER_ITEM: "Prince Per Item",
    ADD_BILL_TYPE: "Add Bill Type",
    FEE_MODEL: "Fee Model",
    BILLING_CHANNEL: "Billing Channel",
    THIRD_PARTY_INTEGRATION: "Third Party Integration",
    PERCENTAGE: "Percentage",
    ADD_FEE_MODEL: "Add Fee Model",
    TYPE: "Type",
    IS_ITEM_BASED: "Is Item Based?",
    AGENT_FEE_AMOUNT: "Agent Fee Amount",
    AGENT_FEE_PERCENTAGE: "Agent Fee Percentage",
    FEE_INCLUSION: "Fee Inclustion",
    HOST: "Host",
    PORT: "Port",
    AUTH_URI: "Auth URI",
    BILL_INQUIRY_URI: "Bill Inquiry URI",
    POST_BILL_URI: "Post Bill URI",
    ADD_THIRD_PARTY_INTEGRATION: "Add Third Party Integration",
    AUTH_URI_METHOD: "Auth URI Method",
    BILL_INFO_INQUIRY_URI: "Bill Info Inquiry URI",
    BILL_INFO_INQUIRY_URI_METHOD: "Bill Info Inquiry URI Method",
    BILL_ADVICE_MESSAGE_URI: "Bill Advice Message URI",
    BILL_ADVICE_MESSAGE_URI_METHOD: "Bill Advice Message URI Method",
    AUTHORIZATION_TYPE: "Authroization Type",
    CREDENTIAL_POSITION: "Credential Position",
    DATA_EXCHANGE_PROTOCOL: "Data Exchange Protocol",
    API_KEY: "API Key",
    API_VALUE: "API Value",
    CLIENT_ID: "Client ID",
    CLIENT_SECRET: "Client Secret",   
    NEW: "New", 
    UN_AUTHORIZED_ACCESS: "You are not Authorized to access this module of the system",

    // This is the dynamic module translation which should be loaded on each module loaded
    M: {},
  },
};
