export const regionalOfficerMenuModule = {
    title: 'SUB_OFFICE',
    root: true,
    alignment: 'left',
    toggle: 'click',
    translate: 'MENU.SUB_OFFICE',
    page: '',
    submenu: [
            {
                title: 'MENU.TRACK_APPLICATIONS',
                page: '/request-application',
                translate: "MENU.TRACK_APPLICATIONS"
            },                
    ]
}