import { KeycloakService } from 'keycloak-angular';
import { administrationMenuModule } from "./menu/administration.menu";
import { regionalOfficerMenuModule } from "./menu/regional_officer.menu";
import { ngoOfficerMenuModule } from "./menu/ngo_officer.menu";
import { procurementOfficerMenuModule } from "./menu/procurement_officer.menu";
import { emergencyOfficerMenuModule } from "./menu/emergency_officer.menu";
import { teamLeadMenuModule } from "./menu/team-lead.menu";


export class MenuConfig {

  constructor(private keycloakService: KeycloakService){}

  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: "Home",
          root: true,
          alignment: "left",
          page: "",
          translate: "MENU.HOME",
        },


      ],
    },
    aside: {
      self: {},
      items: [
        {
          title: "Home",
          root: true,
          alignment: "left",
          page: "",
          translate: "MENU.HOME",
        },
      ],
    },
  };

  public get configs(): any {
    // Add all the menu modules to the main array

    

    if(this.keycloakService.isUserInRole('view_settings_module')){
      this.defaults.header.items.splice(1, 0, administrationMenuModule);
      this.defaults.aside.items.splice(1, 0, administrationMenuModule);
    }

    if(this.keycloakService.isUserInRole('view_regional_officer_module'))
    {
      this.defaults.header.items.splice(1, 0, regionalOfficerMenuModule);
      this.defaults.aside.items.splice(1, 0, regionalOfficerMenuModule);
    }

    if(this.keycloakService.isUserInRole('view_team_lead_module'))
    {
      this.defaults.header.items.splice(1, 0, teamLeadMenuModule);
      this.defaults.aside.items.splice(1, 0, teamLeadMenuModule);
    }

    if(this.keycloakService.isUserInRole('view_organization_module')){
      this.defaults.header.items.splice(1, 0, ngoOfficerMenuModule);
      this.defaults.aside.items.splice(1, 0, ngoOfficerMenuModule);
    }

    if(this.keycloakService.isUserInRole('view_procurement_officer_module')){
      this.defaults.header.items.splice(1, 0, procurementOfficerMenuModule);
      this.defaults.aside.items.splice(1, 0, procurementOfficerMenuModule);
    }

    if(this.keycloakService.isUserInRole('view_emergency_officer_module')){
      this.defaults.header.items.splice(1, 0, emergencyOfficerMenuModule);
      this.defaults.aside.items.splice(1, 0, emergencyOfficerMenuModule);
    }
   
    this.defaults.header.items = this.prepareComponentsUrls(
      this.defaults.header.items
    );

    return this.defaults;
  }

  // Recursively add the parent's page url to the child's page url
  private prepareComponentsUrls(jObject, identifier = "") {
    return jObject.map((obj) => {
      if (obj.hasOwnProperty("page")) {
        obj.page = identifier + obj.page;
        if (obj.hasOwnProperty("submenu")) {
          obj.submenu = this.prepareComponentsUrls(obj.submenu, obj.page);
        }
      }

      return obj;
    });
  }
}
