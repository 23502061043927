export const emergencyOfficerMenuModule = {
    title: 'EMERGENCY_OFFICER',
    root: true,
    alignment: 'left',
    toggle: 'click',
    translate: 'MENU.EMERGENCY_OFFICER',
    page: '',
    submenu: [
        {
            title: 'MENU.TRACK_APPLICATIONS',
            page: '/request-application',
            translate: "MENU.TRACK_APPLICATIONS"
        },     
    ]
}