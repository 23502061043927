import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as moment from 'jalali-moment';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { DatePipe } from '@angular/common';

const _BASE_URL = "/api/generic";
@Injectable({
    providedIn: 'root'
})
export class PagesService {

    years = new Set();
    constructor(
        private httpClient: HttpClient,
        private datePipe: DatePipe,
    ) { }


  
    public convertToDariDate(date) {
        return moment(date, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD');
    }

    public convertToGregorianDate(date) {
        return moment.from(date, 'fa', 'YYYY/MM/DD').format('YYYY-MM-DD');
    }

    public getCurrentJalaliDate() {
        return this.convertToDariDate(new Date());
    }

    greaterThan(field: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const group = control.parent;
            const fieldToCompare = group.get(field);
            const isLessThan = Number(fieldToCompare.value) > Number(control.value);
            return isLessThan ? { 'lessThan': { value: fieldToCompare.value } } : null;
        }
    }

    lessThan(field: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const group = control.parent;
            const fieldToCompare = group.get(field);
            const isGreaterThan = Number(fieldToCompare.value) < Number(control.value);
            return isGreaterThan ? { 'greaterThan': { value: fieldToCompare.value } } : null;
        }
    }

    public getYears() {

        const currentYear = moment().format('YYYY');

        let set = new Set();
        const maxYear = Number(currentYear) + 1;
        const minYear = Number(currentYear) - 3;
        for (let year = minYear; year <= maxYear; year++) {
            this.years.add(year)
        }
        // if (this.years.length < 1) {
        //     this.years.push(currentYear);
        // }

        return Array.from(this.years);
    }


    public getMonths() {

        const months = new Set();
        for(let month = 1; month<=12; month++){
            months.add(month);
        }

        return Array.from(months);
    }


    public getCurrentDate(){
        var date = new Date();
        return this.datePipe.transform(date, 'yyyy/MM/dd');
    }

}
