export const teamLeadMenuModule = {
    title: 'TEAM_LEAD',
    root: true,
    alignment: 'left',
    toggle: 'click',
    translate: 'MENU.APPROVER',
    page: '',
    submenu: [

            {
              title: 'MENU.TRACK_APPLICATIONS',
              page: '/request-application',
              translate: "MENU.TRACK_APPLICATIONS"
          },   
          
        //   {
        //     title: 'MENU.EXPIRATION_DATE_VIEW',
        //     page: '/stock-management/kits-expiration-date',
        //     translate: "MENU.EXPIRATION_DATE_VIEW"
        // },     


            // {
            //   title: 'Organization',
            //   page: '',
            //   translate: "MENU.ORGANIZATION",
            //   alignment: "center",
            //   toggle: "click",
            //   submenu: [
            //     {
            //         title: "CREDIT_ORGANIZATION",
            //         page: "/organization/credit",
            //         translate: "MENU.CREDIT_ORGANIZATION",
            //     },
            //     {
            //         title: "DEBIT_ORGANIZATION",
            //         page: "/organization/debit",
            //         translate: "MENU.DEBIT_ORGANIZATION",
            //     },
            //     {
            //       title: "ORGANIZATION_BALANCE_SHEET",
            //       page: "/organization/balance-sheet",
            //       translate: "MENU.ORGANIZATION_BALANCE_SHEET",
            //     },
            //     {
            //         title: 'ORGANIZATION_REVENUE_REPORT',
            //         page: '/organization-admin/generate-date-based-statement',
            //         translate: "MENU.ORGANIZATION_REVENUE_REPORT"
            //     }
            //   ]
            // },
           
                   
    ]
}