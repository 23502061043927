export const locale = {
	lang: "ps",
	data: {
	  TRANSLATOR: {
		SELECT: "د ژبي ټاکل",
	  },
	  MENU: {
		NEW: "نوي کول",
		ACTIONS: "کړني",
		CREATE_POST: "پوسټ جوړ کړئ",
		PAGES: "پاڼې",
		FEATURES: "ځانګړتیاوې",
		COMPONENTS: " اجزاوي",
		APPS: "اپلیکشنونه ",
		DASHBOARD: "ډشبورډ",
		CUSTOMS: "کسټومز",
		HOME: "کور",
		ADMINISTRATION: "تنظیمات",
		PROVINCE: "ولایت", 
		ORGANIZATION: "سازمان", 
		CENTER: "مرکز", 
		AGENT: "استازی", 
		AGENT_MANAGEMENT: "د استازو مدیریت",
		CREDIT_AGENT: "دکریدت حساب استازی",
		DEBIT_AGENT: "د ډبت حساب استازی",
		BILLTYPE: " د بیل ډول ", 
		FEE_MODEL: "فیس",
		AGENT_BILL_PAYMENT: "د بیل تادیه", 
		AGENT_SLIP_PRINT: "د آویز چاپول", 
		BALANCE_SHEET: "بیلانس شیټ",
		MONITORING: "ارزیابی او نظارت", 
		RECONCILIATION: "سردهی معلومات", 
		STATEMENT: "اسټټمنټ", 
		CENTER_USER_RELATION: "مرکز او یوزر", 
		CONFIRM_BILL_PAYMENT: "د بیل تادیه تایید", 
		ISSUE_BILL: "نوي بیل",
		ORGANIZATION_USER: "کارکوونکی", 
		CENTER_MANAGER: "د مرکز مدیر",
		ORGANIZATION_ADMIN: "د ټولي اداري مدیر", 
		CREDIT_ORGANIZATION: "د سازمان حساب کریډیټ",
		DEBIT_ORGANIZATION: "د سازمان حساب ډیبټ",
		ORGANIZATION_BALANCE_SHEET: "د سازمان بیلانس شیټ",
		ORGANIZATION_REVENUE_REPORT: "د سازمان د عوایدو راپور",
		AGENT_REVENUE_REPORT: "د سازمان د عوایدو راپور",
		AGENT_BALANCE_SHEET: "د استازو بیلانس پاڼه",
		AGENT_MAKE_PAYMENT: "د نماینده لپاره تادیه",
		OUR_REVENUE_REPORT: "د کمپني عواید",
		FINANCE: "مالی",
		PAY_ONLINE_BILL: "بیل آنلاین تادیه کړئ",
		THIRD_PARTY_INTEGRATION: "بهرني سیسټم سره نښلول",
		AGENT_REVENUE: "د استازی عواید",
		REPORTING: "گزارش اخیستل",
	  },
	  CAREFULL_MESSAGE: "سره له دې چې دې برخې ته د لاسرسي اجازه لري، مهرباني وکړئ ډیر پام وکړئ.",
	  ISSUE_BILL: "نوي بیل",
	  BILL_TYPE: "د بیل ډول",
	  CENTER: "مرکز",
	  NUMBER_OF_ITEMS: "د توکو شمیر",
	  IDENTIFIER: "شناس نمبر",
	  BACK: "شاته",
	  SUBMIT: "ثبتول",
	  BILL_PAYMENT_CONFIRMATION: "د بیل تادیاتوتنظیمات",
	  RECEIPT_NUMBER: "د رسید نمبر",
	  DISPLAY: "ښکاره",
	  BILL_DETAILS: "د بیل تفصیلات",  
	  BILL_NO: "د بیل نمبر",
	  BILL_AMOUNT: "د بیل اندازه",
	  BILL_DATE: "د بیل تاریخ",
	  AGENT_NAME: "د استازي نوم",
	  AGENT_ACCOUNT_NO: "د نمایندګي حساب شمیره",
	  AGENT_PHONE_NO: "د نمایندګۍ د اړیکو شمیره",
	  PAYMENT_AMOUNT: "د تادیاتو اندازه",
	  PAYMENT_DATE: "د تادیاتو نیټه",
	  CONFIRM_PAYMENT: "د تادیاتو تایید",
	  CONFIRM_USER: "د کارونکي تایید کول",
	  CONFIRM_DATE: "د تایید نیټه",
	  CONFIRMED: "تایید",
	  VIEW: "ښکاره",
	  PRINT: "پرنت",
	  CLOSE: "بند", 
	  AUTH_ACTIONS: "د پیژندنې فعالیتونه",
	  LOGOUT: "وتل",
	  CHANGE_PASSWORD: "د پټنوم بدلول",
	  GENEATE_REPORT:"د راپورونو تولیدول",
	  FROM_DATE: "له نیټې څخه",
	  TO_DATE: "تر نیټي پوري",
	  STATUS: "حالت",
	  EXPORT: "اکسپورټ",
	  GENERATE_STATEMENT: "د استتمنت تولید",
	  REVENUE_REPORT: "د عوایدو راپور",
	  ORGANIZATION: "سازمان",
	  ORGANIZATION_BALANCE_SHEET: "د سازمان بیلانس شیټ",
	  ACCOUNT_NUMBER: "حساب شمیره",
	  PLEASE_ENTER_THE_ACCOUNT_NUMBER: "د حساب شمیره دننه کړئ",
	  SEARCH: "لټون",
	  BALANCE: "بیلانس",
	  CREDIT: "کریډیټ", 
	  DEBIT: "ډبیټ",
	  BALANCE_DATE: "د بیلانس نیټه",
	  PREVIOUS: "وروستي",
	  RJ_REASON: "د ژورنال د بدلون لامل ",
	  JOURNAL_REFERENCE_NUMBER: "د ژورنال شمیره",
	  AMOUNT: "اندازه",
	  PROVINCE: "ولایت",
	  RJ_TYPE: "د ژورنالیزم د بدلون ډول",
	  COLLECTION_DATE: "د ټولولو نېټه",
	  RJ_DATE: "د ژورنال د بدلون نیټه",
	  CODE: "کوډ",
	  NAME: "نوم",
	  JOURNAL_ENTRY: "د ژورنال ریکارډ",
	  CREDIT_JOURNAL_ENTRY: "د  ژورنال کریډیټ",
	  ORGANIZATION_ACCOUNT: "د سازمان حساب",
	  ACCOUNT_NO: "د حساب شمیره",
	  DEBIT_JOURNAL_ENTRY: "ډیبټ ژورنال",
	  AGENT_ACCOUNT: "د استازی حساب",
	  FIRSTNAME: "نوم",
	  LASTNAME: "تخلص",
	  AGENT_FEE_APPROVAL: "د نمایندګۍ فیس او عاید تایید",
	  ALL: "ټول",
	  AGENT_FEE: "د نمایندګۍ فیس",
	  FEE_DATE: "د فیس نیټه",
	  IS_CLEARED: "تصف شوی؟",
	  APPROVE: "تصدیق",
	  REJECT: "رد",
	  BALANCE_SHEET: "بیلانس شیټ",
	  AGENT_PAYMENT: "استازي ته تایدیه ",
	  PAYMENT_JOURNAL_ENTRY: "د تادیاتو ژورنال",
	  OFFLINE_BILL_PAYMENT: "د بیل تادیه",
	  BILL_NUMBER: "د بیل نمبر",
	  BILL_INFORMATION: "د بیل معلومات",
	  BILL_DUE_DATE: "د بیل تادیه نیټه",
	  CYCLE: "دوره",
	  YEAR: "سال",
	  PAYMENT_DETAILS: "د تادیاتو توضیحات",
	  MODE_OF_PAYMENT: "د تادیاتو حالت",
	  CASH: "نغدي",
	  ELECTRONIC: "برقی پیسې",
	  TENDERED_AMOUNT: "مقدار مناقصه",
	  AMOUNT_PAID: "د تادیاتو اندازه",
	  RETURN_AMOUNT: "د بیرته ستنیدو اندازه",
	  ONLINE_BILL_PAYMENT: " بیل آنلاین تادیه کړئ",  
	  ARE_YOU_SURE: "ایا تاسو ډاډه یاست؟",
	  PROCESSING:  "د پراسس په حال کي .....",
	  THE_OPERATION_SUCCESSFULLY_DONE: "غوښتنه په بریالیتوب سره پروسس شوه. مننه",
	  CONFIRMATION: "د تایید اړتیا",
	  DUPLICATE_SLIP_PRINT: "د آویز بیا ځلي پرنټ",
	  PROVINCE_CODE: "ولایت کوډ",
	  ACTIONS: "فعالیتونه",
	  ADD_PROVINCE:"ولایت جوړ کړئ",
	  PROVINCE_USER_RELATION: "ولایت او کارونکي",
	  USERNAME: "د کارونکي د حساب نوم",
	  DELETED: "حذف شوي؟",
	  ADD_ORGANIZATION: "د سازمان حساب",
	  PHONE: "د تلیفون شمیره",
	  EMAIL: "برېښلیک",
	  ADDRESS: "آدرس",
	  BANK_NAME: "د بانک نوم",
	  BANK_ACCOUNT_NO: "د بانک حساب شمیره",
	  BANK_CARD_HOLDER_NAME: "د کارت لرونکی نوم",
	  BANK_CARD_NO: "د FP کارت شمیره",
	  ORGANIZATION_USER_RELATION: "سازمان او یوزر",
	  CENTER_USER_RELATION: "مرکز او یوزر",
	  ADD_CENTER: "مرکز جوړ کړئ",
	  PARENT_CENTER: "د پلار مرکز",
	  AGENT_USER_RELATION: "استازی او کارونکي",
	  ADD_AGENT: "استازی جوړ کړئ",
	  FATHERNAME: "د پلار نوم",
	  GRANT_FATHERNAME: "د نيکه نوم",
	  TAZKIRA_NO: "تذکره شمېره",
	  FRIEND_ACCOUNT_NO1: "د۱  ملګري حساب نمبر",
	  FRIEND_ACCOUNT_NO2: "د۲  ملګري حساب نمبر",
	  PRICE_PER_ITEM: "د هر توکي قیمت",
	  ADD_BILL_TYPE: "د بیل ډول اضافه کړئ",
	  FEE_MODEL: " فیس مډل ",
	  BILLING_CHANNEL: "د  بلنگ چنل",
	  THIRD_PARTY_INTEGRATION: "بهرني سیسټم سره نښلول",
	  PERCENTAGE: "سلنه",
	  ADD_FEE_MODEL: "د فیس ماډل اضافه کړئ",
	  TYPE: "ډول",
	  IS_ITEM_BASED: "د توکي په واسطه؟",
	  AGENT_FEE_AMOUNT: "د نمایندګۍ د فیس اندازه",
	  AGENT_FEE_PERCENTAGE: "د نمایندګۍ فیس سلنه",
	  FEE_INCLUSION: "د فیس شاملول",
	  HOST: "هاسټ",
	  PORT: "پورټ",
	  AUTH_URI: " دURL پیژندني تصدیق",
	  BILL_INQUIRY_URI: "URL  درخواست معلومات بل",
	  POST_BILL_URI: "URL ارسال معلومات بل",
	  ADD_THIRD_PARTY_INTEGRATION: "ایجاد وصل با سیستم بیرونی",
	  AUTH_URI_METHOD: "URI Method تثبیت هویت",
	  BILL_INFO_INQUIRY_URI: "URL  درخواست معلومات بل",
	  BILL_INFO_INQUIRY_URI_METHOD: "URL Method درخواست معلومات بل",
	  BILL_ADVICE_MESSAGE_URI: "URL ارسال معلومات بل",
	  BILL_ADVICE_MESSAGE_URI_METHOD: "URL Method ارسال معلومات بل",
	  AUTHORIZATION_TYPE: "نوعیت شناسای و تثبیت هویت",
	  CREDENTIAL_POSITION: "موقیعت معلومات هویت",
	  DATA_EXCHANGE_PROTOCOL: "پروتوکل تبادله معلومات",
	  API_KEY: "API Key",
	  API_VALUE: "API Value",
	  CLIENT_ID: "Client ID",
	  CLIENT_SECRET: "Client Secret",    
	  NEW: "نوی",
	  UN_AUTHORIZED_ACCESS: "تاسو د دی بخش صلاحیت نه لری",
  
	  // This is the dynamic module translation which should be loaded on each module loaded
	  M: {},
	},
  };
  