// Angular
import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataExchangeService } from 'app/core/service/data.exchange.service';
import { RequestApplicationService } from 'app/views/pages/onlinepayment/request-application/service/request-application.service';
import { Observable, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
	selector: 'kt-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['notification.component.scss']
})
export class NotificationComponent {

	// Show dot on top of the icon
	@Input() dot: string;

	// Show pulse on icon
	@Input() pulse: boolean;

	@Input() pulseLight: boolean;

	// Set icon class name
	@Input() icon: string = 'flaticon2-bell-alarm-symbol';
	@Input() iconType: '' | 'success';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG: boolean;

	// Set bg image path
	@Input() bgImage: string;

	// Set skin color, default to light
	@Input() skin: 'light' | 'dark' = 'light';

	@Input() type: 'brand' | 'success' = 'success';
	notifications: Array<any> = [];

	datePipe: DatePipe = new DatePipe('en-US');

	/**
	 * Component constructor
	 *
	 * @param sanitizer: DomSanitizer
	 */
	constructor(
		 private sanitizer: DomSanitizer,
		 private requestApplicationService: RequestApplicationService,
		 private router :Router,
		 private dataExchangeService: DataExchangeService
		) {
		// this.loadNotifications();

		const reloadInterval = 10000;
		timer(0, reloadInterval).pipe(
		mergeMap(_ => this.requestApplicationService.getRequestApplicationHistories())).subscribe(response=>{ 
			// console.log("nots", response);
			this.notifications= response == null ? [] : response;
		});
	}


	loadNotifications(){
		this.requestApplicationService.getRequestApplicationHistories().subscribe(response =>{
			// console.log("notifications", response);
			this.notifications= response == null ? [] : response;
		});
	}

	transformDate(date){
		return this.datePipe.transform(date, 'yyyy/MM/dd');
	}

	backGroundStyle(): string {
		if (!this.bgImage) {
			return 'none';
		}

		return 'url(' + this.bgImage + ')';
	}

	notificationSelected(item){
		console.log("Notification", item);
		this.redicrect(item);
		this.requestApplicationService.updateNotficationHistory(item.id).subscribe(response => {
			this.notifications= response == null ? [] : response;
			this.redicrect(item);
		});
	}

	redicrect(item) {
		this.dataExchangeService.changeData(item.requestApplication);
        this.router.navigate(['/request-application/open-request-application']);
		//   this.router.navigate(['/request-application']);
	}
}
