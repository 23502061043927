<!-- begin:: Content Head -->
<div
  class="kt-subheader kt-grid__item"
  [ngClass]="{ 'kt-container--clear': clear }"
  [hidden]="subheaderService.disabled$ | async"
  id="kt_subheader"
>
  <div class="kt-container" [ngClass]="{ 'kt-container--fluid': fluid }">
    <div class="kt-subheader__main">
      <h3 *ngIf="title" class="kt-subheader__title">{{ title }}</h3>
      <span class="kt-subheader__separator kt-subheader__separator--v"></span>
      <!--<h4 *ngIf="desc" class="kt-subheader__desc">{{desc}}</h4>-->
      <!-- <h4 class="kt-subheader__desc">#XRS-45670</h4>
			<a href="javascript:;" class="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10">
				Add New
			</a> -->
    </div>
    <div class="kt-subheader__toolbar">
      <div class="kt-subheader__wrapper">
        <!--<a href="javascript:;" class="btn kt-subheader__btn-secondary">Today</a>-->
        <!--<a href="javascript:;" class="btn kt-subheader__btn-secondary">Month</a>-->
        <!--<a href="javascript:;" class="btn kt-subheader__btn-secondary">Year</a>-->
      </div>
    </div>
  </div>
</div>
<!-- end:: Content Head -->
