<!--begin: Quick actions -->
<div ngbDropdown placement="bottom" autoClose="outside" class="kt-header__topbar-item">
	<div ngbDropdownToggle class="kt-header__topbar-wrapper">
		<span class="kt-header__topbar-icon" [ngClass]="{ 'kt-header__topbar-icon--warning': iconType === 'warning' }">
			<i *ngIf="!useSVG" [ngClass]="icon"></i>
			<span *ngIf="useSVG" class="kt-svg-icon" [inlineSVG]="icon"
				[ngClass]="{ 'kt-svg-icon--warning': iconType === 'warning' }"></span>
		</span>
	</div>
	<div ngbDropdownMenu
		class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
		<form>
			<!--begin: Head -->
			<div class="kt-head kt-head--skin-{{skin}}" [ngStyle]="{'background-image': 'url('+bgImage+')'}">
				<h3 class="kt-head__title">
					{{'AUTH_ACTIONS'|translate}}
					<span class="kt-space-15"></span>
					<span class="btn btn-success btn-sm btn-bold btn-font-md" (click)="logout()">{{'LOGOUT'|translate}}</span> &nbsp;
					<span class="btn btn-success btn-sm btn-bold btn-font-md" (click)="changePassword()">{{'CHANGE_PASSWORD'|translate}}</span>
				</h3>
			</div>
			<!--end: Head -->
		</form>
	</div>
</div>
<!--end: Quick actions -->